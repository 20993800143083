import { Component, Fragment } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 3rem;
  min-height: 42vh;
`

class PressReleasePage extends Component
{
	render()
	{
		const data = this.props.data

		const pStyle = {
			whiteSpace: "pre-line"
		}

		return (
			<Fragment>
				<Wrapper>
					<h1>{data.title}</h1>
					<br/>
					<h2>{data.subHeading}</h2>
					<br/>
					<p style={pStyle}>{data.location} ({data.date}) - {data.body}</p>
				</Wrapper>
			</Fragment>
		)
	}
}

export default PressReleasePage
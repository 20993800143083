import { Component, Fragment } from 'react'
import '../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import $ from 'jquery'
import '../../assets/css/main.css'

class Carousel extends Component
{
	componentDidMount()
	{
		const carousel = document.getElementById(this.props.data.id)

	    if (window.matchMedia("(min-width: 768px)").matches)
	    {
	        this.slidesContainer = carousel.getElementsByClassName("carousel-inner")[0]
	        this.slidesViewCount = 3

	        this.slidesCount = this.slidesContainer.children.length

	        this.slideIndex = 0
	        this.animationDuration = 600
	        this.isAnimating = false
	        this.autoScrollTimer = null

	        this.initFillerSlides()

	        const prevButton = carousel.getElementsByClassName("carousel-control-prev")[0]
	        prevButton.onclick = () => {
	            this.scrollLeft()
	        }

	        const nextButton = carousel.getElementsByClassName("carousel-control-next")[0]
	        nextButton.onclick = () => {
	            this.scrollRight()
	        }

	        this.autoScroll(5000)
	    }
	    else
	    {
	        carousel.classList.add("slide")
	    }
	}

	render()
    {
    	return (
        	<Fragment>
	            <div id="carousel-word-master" className="carousel" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        { this.renderSlides() }
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carousel-word-master" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon"></span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carousel-word-master" data-bs-slide="next">
                        <span className="carousel-control-next-icon"></span>
                    </button>
                </div>
		    </Fragment>
        )
    }

    renderSlides()
	{
		let slides = []

		for (let i = 0; i < this.props.data.slides.length; i++)
		{
			const image = this.props.data.slides[i].image
			const alt = this.props.data.slides[i].alt

			let classAttribute = "carousel-item"
			if (i === 0)
			{
				classAttribute += " active"
			}

			slides.push(
				<div key={i} className={classAttribute}>
                    <img src={image} alt={alt} className="d-block w-100" />
                </div>
			)
		}

		return (
			<Fragment>
				{slides}
			</Fragment>
		)
	}

	initFillerSlides()
    {
        let firstChild = this.slidesContainer.children[0]
        let lastChild = this.slidesContainer.children[this.slidesCount - 1]

        for (let i = 0; i < this.slidesViewCount - 1; i++)
        {
            let clone = this.slidesContainer.children[i].cloneNode(true)
            if (i === 0)
            {
            	clone.classList.remove("active")
            }
            this.slidesContainer.appendChild(clone)
        }
        
        let lastChildClone = lastChild.cloneNode(true)
        this.slidesContainer.insertBefore(lastChildClone, firstChild)
    }

    scrollLeft()
    {
        if (this.isAnimating)
        {
            return
        }

        if (this.slideIndex == 0)
        {
            this.slideIndex = this.slidesCount
            this.scrollTo(this.slideIndex, 0)
        }

        this.slideIndex--

        this.scrollTo(this.slideIndex, this.animationDuration)
    }

    scrollRight()
    {
        if (this.isAnimating)
        {
            return
        }

        this.slideIndex++

        let callback = () => {
            if (this.slideIndex >= this.slidesCount)
            {
                this.slideIndex = 0
                this.scrollTo(this.slideIndex, 0)
            }
            else
            {
                this.isAnimating = false
                this.autoScroll(5000)
            }
        }

        this.scrollTo(this.slideIndex, this.animationDuration, callback)
    }

    scrollTo(slideIndex, animationDuration)
    {
        this.scrollTo(slideIndex, animationDuration, null)
    }

    scrollTo(slideIndex, animationDuration, callback)
    {
        // do it here instead of in componentDidMount() since componentDidMount() would calculate the wrong width for some reason
        if (!this.slideWidth)
        {
            this.slideWidth = this.slidesContainer.getElementsByClassName("carousel-item")[0].offsetWidth
        }

        this.isAnimating = true

        this.resetAutoScrollTimer()

        $(this.slidesContainer).animate(
            { scrollLeft: slideIndex * this.slideWidth },
            animationDuration,
            () => {
                if (callback != null)
                {
                    callback()
                }
                else
                {
                    this.isAnimating = false
                    this.autoScroll(5000)
                }
            }
        )
    }

    autoScroll(delay)
    {
        if (this.autoScrollTimer != null)
        {
            return
        }

        let callback = () => {
            this.resetAutoScrollTimer()

            this.scrollRight()
        }

        this.autoScrollTimer = setTimeout(callback, delay)
    }

    resetAutoScrollTimer()
    {
        clearTimeout(this.autoScrollTimer)
        this.autoScrollTimer = null
    }
}

export default Carousel
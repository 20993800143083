import { Component, Fragment } from 'react'
import { Sections } from '../components/sections'
import HomeSection from './HomeSection'
import AboutSection from './AboutSection'

class HomePage extends Component
{
	render()
	{
		return (
			<Fragment>
                <HomeSection />
				<Sections data={this.props.sectionsData} />
                <AboutSection data={this.props.aboutData} />
			</Fragment>
		)
	}
}

export default HomePage
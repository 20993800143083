import { Component, Fragment } from 'react'

class HomeSection extends Component
{
	render()
	{
		return (
			<Fragment>
				<section className="container-fluid section-bg-img bg-home" id="home">
                    <div className="container-stretched">
                        <div className="element-centered">
                            <h1 className="title">Nexus Entertainment Studios</h1>
                            <div className="space-20"></div>
                            <h3>The Worldwide Leader in Innovative Entertainment</h3>
                        </div>
                    </div>
                </section>
			</Fragment>
		)
	}
}

export default HomeSection
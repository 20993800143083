import { Component, Fragment } from 'react'
import { Carousel, YoutubeVideo } from '../ui'
import { kebabCase } from '../../utils/string'
import '../../assets/css/main.css'

class Section extends Component
{
	render()
    {
    	const id = kebabCase(this.props.data.title)
    	const background = "bg-" + id
    	let sectionClassName = "container-fluid section-bg-img " + background

    	if (this.props.data.videoData)
    	{
    		sectionClassName = "container-fluid section-bg-video"
    	}

    	return (
        	<Fragment>
	            <section className={sectionClassName} id={id}>
			        { this.renderBackground() }
			    </section>
		    </Fragment>
        )
    }

    renderBackground()
	{
		if (!this.props.data.videoData)
		{
			return this.renderContentsContainer()
		}

		return (
			<Fragment>
				<YoutubeVideo data={this.props.data.videoData} renderContentsContainer={this.renderContentsContainer()}>
				</YoutubeVideo>
			</Fragment>
		)
	}

	renderContentsContainer()
	{
		return (
			<Fragment>
				<div className="container-stretched">
		            <div className="element-centered">
		                <h1>{this.props.data.title}</h1>
		                <div className="space-20"></div>
		                <h4>{this.props.data.description}</h4>
		                <div className="space-20"></div>
		                <div className="text-center">
		                    <a href={this.props.data.appStoreURL}>
		                        <img className="btn-download" src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" alt="Download on the App Store" />
		                    </a>
		                    <a href={this.props.data.googlePlayURL}>
		                        <img className="btn-download" src="https://lh3.googleusercontent.com/cjsqrWQKJQp9RFO7-hJ9AfpKzbUb_Y84vXfjlP0iRHBvladwAfXih984olktDhPnFqyZ0nu9A5jvFwOEQPXzv7hr3ce3QVsLN8kQ2Ao=s0" alt="Get it on Google Play" />
		                    </a>
		                </div>
		                { this.renderCarousel() }
		            </div>
		        </div>
			</Fragment>
		)
	}

	renderCarousel()
	{
		if (!this.props.data.carousel)
		{
			return;
		}

		return (
			<Fragment>
				<div className="space-20"></div>
				<Carousel data={this.props.data.carousel} />
			</Fragment>
		)
	}
}

export default Section
import { Component } from 'react'
import Section from './Section'

class Sections extends Component
{
    render()
    {
    	return (
    		this.props.data.map(item => (
    			<Section key={item.title} data={item}/>
    		))
		)
    }
}

export default Sections
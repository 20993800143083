import { Component, Fragment } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 3rem;
  min-height: 42vh;
`

const WrapperA = styled.a`
  color: gray;
  text-decoration: none;
`

const WrapperH3 = styled.h3`
  color: white;
  text-decoration: none;
  text-align: left !important;
`

class PressReleasesPage extends Component
{
	render()
	{
		const pressReleases = this.props.data["pressReleases"]

		return (
			<Fragment>
				<h1>Press Releases</h1>
				<Wrapper>
				{
					pressReleases.map((data, key) => {
						const path = "/press-releases/" + key
						return (
							<div key={key}>
								<WrapperA href={path}>
									<p>{data.date}</p>
								</WrapperA>
								<WrapperA href={path}>
									<WrapperH3>{data.title}</WrapperH3>
								</WrapperA>
								<hr/>
								<br/>
							</div>
						)
					})
				}
				</Wrapper>
			</Fragment>
		)
	}
}

export default PressReleasesPage
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { kebabCase } from '../../utils/string'
import '../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'

const Item = styled.li.attrs({
    className: 'nav-item',
})
`
`

class Links extends Component
{
    renderMenuItems()
    {
        const menuItems = this.props.data;

        let items = []

        for (let i = 0; i < menuItems.length; i++)
        {
            let item = menuItems[i]
            let to = "/#" + kebabCase(item)

            items.push(
                <Item key={i}>
                    <a href={to} className="nav-link">
                        {item}
                    </a>
                </Item>
            )
        }

        return items
    }

    render()
    {
        return (
            <Fragment>
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand">
                        Nexus Entertainment Studios
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            { this.renderMenuItems() }
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Links
import { Component, Fragment } from 'react'
import styled from 'styled-components'
import parse from "html-react-parser"

const Wrapper = styled.div`
  margin: 3rem;
`

class HTMLFromDataPage extends Component
{
	constructor(props) {
        super(props)
        this.state = {
            text: "",
            isLoading: false,
        }
    }

	componentDidMount = async () => {
        this.setState({ isLoading: true })

        await fetch(this.props.data)
		.then(r => r.text())
		.then(text => {
			this.setState({
                text: text,
                isLoading: false,
            })
		})
    }

	render()
	{
		const { text, isLoading } = this.state

		return (
			<Fragment>
				<Wrapper>{parse(text)}</Wrapper>
			</Fragment>
		)
	}
}

export default HTMLFromDataPage
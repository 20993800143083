import { Component, Fragment } from 'react'
import { FacebookButton, TwitterButton } from '../buttons'
import { kebabCase } from '../../utils/string'

class Footer extends Component
{
	renderSocials()
	{
		if (this.props.data.socials.length === 0)
		{
			return
		}

		let socials = [];

		const buttonTypes = {
			"Facebook": FacebookButton,
			"Twitter": TwitterButton
		}

		this.props.data.socials.forEach(item => {
			const ButtonType = buttonTypes[item.name]
			socials.push(<ButtonType key={item.name} url={item.url} />)
		})

		return (
			<Fragment>
				<p className="text-secondary">Follow us on</p>
	            <div className="space-20"></div>
	            <div>
	                {socials}
	            </div>
	            <div className="space-20"></div>
            </Fragment>
		)
	}

	renderLinks()
	{
		if (this.props.data.links.length === 0)
		{
			return
		}

		let links = [];

		this.props.data.links.forEach(item => {
			const url = "/" + kebabCase(item)
			links.push(
				<li key={item} className="nav-item">
                	<a className="nav-link text-secondary" href={url}>{item}</a>
            	</li>
            )
		})

		return (
			<Fragment>
	            <div className="container-fluid">
	                <ul className="nav justify-content-center">
		                {links}
		            </ul>
	            </div>
            </Fragment>
		)
	}

    render()
    {
    	return (
            <footer className="p-4 bg-dark bg-footer text-center">
		        <div className="text-secondary">
		            { this.renderSocials() }
		            { this.renderLinks() }
		        </div>
		        <p className="text-secondary">{this.props.data.copyright}</p>
		    </footer>
        )
    }
}

export default Footer
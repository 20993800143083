import axios from 'axios'

const api = axios.create({
    //baseURL: 'http://localhost:3000/api',
    baseURL: 'https://nexus-website-server.herokuapp.com/api',
})

export const insertApp = payload => api.post(`/app`, payload)
export const getAllApps = () => api.get(`/apps`)
export const updateAppById = (id, payload) => api.put(`/app/${id}`, payload)
export const deleteAppById = id => api.delete(`/app/${id}`)
export const getAppById = id => api.get(`/app/${id}`)

const apis = {
    insertApp,
    getAllApps,
    updateAppById,
    deleteAppById,
    getAppById,
}

export default apis
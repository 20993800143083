import React, { Component } from 'react'
import { useTable } from 'react-table'
import api from '../api'

import styled from 'styled-components'

//import 'react-table/react-table.css'

const Wrapper = styled.div`
  padding: 1rem;
  overflow: auto;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell', cell.row)}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const Update = styled.div`
    color: #ef9b0f;
    cursor: pointer;
`

const Delete = styled.div`
    color: #ff0000;
    cursor: pointer;
`

class UpdateApp extends Component {
    updateUser = event => {
        event.preventDefault()

        window.location.href = `/apps/update/${this.props.id}`
    }

    render() {
        return <Update onClick={this.updateUser}>Update</Update>
    }
}

class DeleteApp extends Component {
    deleteUser = event => {
        event.preventDefault()

        if (
            window.confirm(
                `Do you want to delete the app ${this.props.id} permanently?`,
            )
        ) {
            api.deleteAppById(this.props.id)
            window.location.reload()
        }
    }

    render() {
        return <Delete onClick={this.deleteUser}>Delete</Delete>
    }
}

class AppsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            apps: [],
            columns: [],
            isLoading: false,
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true })

        await api.getAllApps().then(apps => {
            this.setState({
                apps: apps.data.data,
                isLoading: false,
            })
        })
    }

    render() {
        const { apps, isLoading } = this.state

        const columns = [
            {
                Header: 'ID',
                accessor: '_id',
                filterable: true,
            },
            {
                Header: 'Title',
                accessor: 'title',
                filterable: true,
            },
            {
                Header: 'Description',
                accessor: 'description',
                filterable: true,
            },
            {
                Header: 'App Store URL',
                accessor: 'appStoreURL',
                filterable: true,
            },
            {
                Header: 'Google Play URL',
                accessor: 'googlePlayURL',
                filterable: true,
            },
            {
                Header: ' ',
                accessor: '',
                Cell: function(props) {
                    return (
                        <span>
                            <DeleteApp id={props.original._id} />
                        </span>
                    )
                },
            },
            {
                Header: '  ',
                accessor: '',
                Cell: function(props) {
                    return (
                        <span>
                            <UpdateApp id={props.original._id} />
                        </span>
                    )
                },
            },
        ]

        let showTable = true
        if (!apps.length) {
            showTable = false
        }

        return (
            <Wrapper>
                {showTable && (
                    <Table
                        data={apps}
                        columns={columns}
                        loading={isLoading}
                        defaultPageSize={10}
                        showPageSizeOptions={true}
                        minRows={0}
                    />
                )}
            </Wrapper>
        )
    }
}

export default AppsList
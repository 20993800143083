import { Component, Fragment } from 'react'
import '../../assets/css/main.css'

class YoutubeVideo extends Component
{
	componentDidMount = () => {
		if (!window.loadingYT)
		{
			window.videos = []
    		this.loadYoutubeAPI()
        }

        window.videos.push(this.props.data)
        window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady
    }

	loadYoutubeAPI()
	{
		window.loadingYT = true

		// 2. This code loads the IFrame Player API code asynchronously.
		let tag = document.createElement('script');
		tag.src = "https://www.youtube.com/iframe_api";

		let firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	}

	// 3. This function creates an <iframe> (and YouTube player) after the API code downloads.
	onYouTubeIframeAPIReady = () => {
		const videos = window.videos

		for (let videoData of videos)
		{
			let player = new window.YT.Player(videoData.playerID,
				{
					height: '390',
					width: '640',
					videoId: videoData.videoID,
					playerVars: {
						'playsinline': 1,
						'controls': 0,
						'mute': 1,
						'cc_load_policy': 3,
						'iv_load_policy': 3,
						'autoplay': 1,
						'playlist': videoData.videoID,
						'loop': 1,
						'modestbranding': 1
					},
					events: {
						// 4. The API will call this function when the video player is ready.
						'onReady': (event) => {
							if (videoData.start && videoData.end)
							{
								event.target.loadVideoById({'videoId': videoData.videoID,
									'startSeconds': videoData.start,
									'endSeconds': videoData.end});
								return
							}

							event.target.loadVideoById({'videoId': videoData.videoID})
						},
						// 5. The API calls this function when the player's state changes.
						//    The function indicates that when playing a video (state=1),
						//    the player should play for six seconds and then stop.
						'onStateChange': (event) => {
							if (event.data === window.YT.PlayerState.ENDED) {
								if (videoData.start)
								{
									event.target.seekTo(videoData.start)
									return
								}

								event.target.seekTo(0)
							}
						}
					}
				}
			)
		}
	}

	render()
    {
    	return (
			<Fragment>
				<div className="video-container">
					<div id={this.props.data.playerID} class="video">
		            </div>
		        </div>
		        <div className="video-container-overlay">
					{ this.props.renderContentsContainer }
				</div>
			</Fragment>
		)
    }
}

export default YoutubeVideo
import React, { Component } from 'react'
import { useParams } from "react-router-dom";
import api from '../api'

import styled from 'styled-components'

const Title = styled.h1.attrs({
    className: 'h1',
})``

const Wrapper = styled.div.attrs({
    className: 'form-group',
})`
    margin: 0 30px;
`

const Label = styled.label`
    margin: 5px;
`

const InputText = styled.input.attrs({
    className: 'form-control',
})`
    margin: 5px;
`

const Button = styled.button.attrs({
    className: `btn btn-primary`,
})`
    margin: 15px 15px 15px 5px;
`

const CancelButton = styled.a.attrs({
    className: `btn btn-danger`,
})`
    margin: 15px 15px 15px 5px;
`

export function withRouter(Children) {
     return (props) => {

        const match  = {params: useParams()};
        return <Children {...props}  match = {match}/>
    }
}

class AppsUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            title: '',
            description: '',
            appStoreURL: '',
            googlePlayURL: '',
        }
    }

    handleChangeInputTitle = async event => {
        const title = event.target.value
        this.setState({ title })
    }

    handleChangeInputDescription = async event => {
        const description = event.target.value
        this.setState({ description })
    }

    handleChangeInputAppStoreURL = async event => {
        const appStoreURL = event.target.value
        this.setState({ appStoreURL })
    }

    handleChangeInputGooglePlayURL = async event => {
        const googlePlayURL = event.target.value
        this.setState({ googlePlayURL })
    }

    handleUpdateApp = async () => {
        const { id, title, description, appStoreURL, googlePlayURL } = this.state
        const payload = { title, description, appStoreURL, googlePlayURL }

        await api.updateAppById(id, payload).then(res => {
            window.alert(`App updated successfully`)
            this.setState({
                title: '',
                description: '',
                appStoreURL: '',
                googlePlayURL: '',
            })
        })
    }

    componentDidMount = async () => {
        const { id } = this.state
        const app = await api.getAppById(id)

        this.setState({
            title: app.data.data.title,
            description: app.data.data.description,
            appStoreURL: app.data.data.appStoreURL,
            googlePlayURL: app.data.data.googlePlayURL,
        })
    }

    render() {
        const { title, description, appStoreURL, googlePlayURL } = this.state
        return (
            <Wrapper>
                <Title>Update App</Title>

                <Label>Title: </Label>
                <InputText
                    type="text"
                    value={title}
                    onChange={this.handleChangeInputTitle}
                />

                <Label>Description: </Label>
                <InputText
                    type="text"
                    value={description}
                    onChange={this.handleChangeInputDescription}
                />

                <Label>App Store URL: </Label>
                <InputText
                    type="text"
                    value={appStoreURL}
                    onChange={this.handleChangeInputAppStoreURL}
                />

                <Label>Google Play URL: </Label>
                <InputText
                    type="text"
                    value={googlePlayURL}
                    onChange={this.handleChangeInputGooglePlayURL}
                />

                <Button onClick={this.handleUpdateApp}>Update App</Button>
                <CancelButton href={'/apps/list'}>Cancel</CancelButton>
            </Wrapper>
        )
    }
}

export default withRouter(AppsUpdate)
import { Component } from 'react'

import Logo from './Logo'
import Links from './Links'

class Bar extends Component
{
	renderLogo()
	{
		if (this.props.data.useLogo)
		{
			return <Logo />
		}
	}

    render()
    {
    	return (
            <nav className="navbar navbar-expand-sm bg-dark navbar-dark sticky-top">
            	{ this.renderLogo() }
                <Links data={this.props.data.menuItems} />
            </nav>
        )
    }
}

export default Bar
import { Component } from 'react'

import logo from '../../assets/img/favicon.png'

class Logo extends Component
{
    render()
    {
        return (
            <a href="/" className="navbar-brand">
                <img src={logo} width="50" height="50" alt="Nexus Entertainment Studios" />
            </a>
        )
    }
}

export default Logo
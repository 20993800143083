import { Component, Fragment } from 'react'
import styled from 'styled-components'
import { YoutubeVideo } from '../components/ui'

const Wrapper = styled.p`
  text-align: center !important;
`

class AboutSection extends Component
{
	render()
	{
		return (
			<Fragment>
				<section className="container-fluid section-bg-video bg-about" id="about">
					<YoutubeVideo data={this.props.data} renderContentsContainer={this.renderContentsContainer()}>
					</YoutubeVideo>
			    </section>
			</Fragment>
		)
	}

	renderContentsContainer()
	{
		return (
			<Fragment>
				<div className="container-stretched">
	                <div className="element-centered">
	                    <h1>Our Mission</h1>
	                    <div className="space-20"></div>
	                    <Wrapper>The mission of Nexus Entertainment Studios is to entertain, inform and inspire people around the globe through the power of world-class innovative entertainment, reflecting the iconic brands, creative minds and innovative technologies that make ours the world’s premier entertainment company.</Wrapper>
	                </div>
	            </div>
			</Fragment>
		)
	}
}

export default AboutSection
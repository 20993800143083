import 'bootstrap/dist/css/bootstrap.min.css'

import React, { Fragment } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { Bar } from '../components/navigation'
import { HomePage, HTMLFromDataPage, PressReleasesPage, PressReleasePage } from '../views'
import { Footer } from '../components/footer'

import { AppsList, AppsInsert, AppsUpdate } from '../pages'

import screenshot1 from '../assets/img/screenshot1.png'
import screenshot2 from '../assets/img/screenshot2.png'
import screenshot3 from '../assets/img/screenshot3.png'
import screenshot4 from '../assets/img/screenshot4.png'

import termsOfServiceData from '../assets/data/terms-of-service.txt'
import privacyPolicyData from '../assets/data/privacy-policy.txt'
import pressReleasesData from '../assets/data/press-releases.json'
import disclaimerData from '../assets/data/disclaimer.txt'

const NavigationBar = Bar;

const sectionsData = [
    {
        title: "Word Master",
        description: "The Hit Word Game of 2022!",
        appStoreURL: "https://apps.apple.com/us/app/nexus-metaverse/id1607821588",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.nexusentertainmentstudios.metaverse",
        carousel: {
            id: "carousel-word-master",
            slides: [
                {
                    image: screenshot1,
                    alt: "Play the hit word game of 2022!"
                },
                {
                    image: screenshot2,
                    alt: "Can you guess the word?"
                },
                {
                    image: screenshot3,
                    alt: "Over 1000 different words to guess!"
                },
                {
                    image: screenshot4,
                    alt: "Compete against other players!"
                }
            ]
        }
    },
    {
        title: "Nexus Metaverse",
        description: "The Metaverse for Games and Entertainment",
        appStoreURL: "https://apps.apple.com/us/app/nexus-metaverse/id1607821588",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.nexusentertainmentstudios.metaverse"
    },
    {
        title: "Infopedia",
        description: "The World's Best Companion App When Playing Pokémon",
        appStoreURL: "https://apps.apple.com/us/app/nexus-metaverse/id1607821588",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.nexusentertainmentstudios.metaverse",
        "videoData": {
            "playerID": "infopediaVideoPlayer",
            "videoID": "fPO2ouGn0lA",
            "start": 5,
            "end": 30
        }
    },
    {
        title: "Slots Master",
        description: "The Most Innovative Free Slots Game of the Decade",
        appStoreURL: "https://apps.apple.com/us/app/nexus-slots-master/id1494245752",
        googlePlayURL: "https://play.google.com/store/apps/details?id=com.nexusentertainmentstudios.slots",
        "videoData": {
            "playerID": "slotsMasterVideoPlayer",
            "videoID": "WJ72YYTvUII"
        }
    }
]

const aboutData = {
    "playerID": "aboutVideoPlayer",
    "videoID": "ynkKL3R0rhc"
}

let menuItems = []
menuItems.push("Home")
sectionsData.forEach(item => {
    menuItems.push(item.title)
})
menuItems.push("About")

const navbarData = {
    menuItems: menuItems,
    useLogo: false
}

const footerData = {
    socials: [
        {
            name: "Facebook",
            url: "https://www.facebook.com/Nexus-Entertainment-Studios-102998554581880"
        },
        {
            name: "Twitter",
            url: "https://twitter.com/nexusentstudios"
        }
    ],
    links: [
        "Terms of Service",
        "Privacy Policy",
        "Press Releases",
        "Disclaimer"
    ],
    copyright: "© Nexus Entertainment Studios, All Rights Reserved"
}

const pressReleases = pressReleasesData["pressReleases"]

function App() {
    return (
        <Fragment>
            <Router>
            	<NavigationBar data={navbarData} />
                <Routes>
                    <Route path="/apps/list" exact element={<AppsList />} />
                    <Route path="/apps/create" exact element={<AppsInsert />} />
                    <Route path="/apps/update/:id" exact element={<AppsUpdate />} />
                    <Route path="/" exact element={<HomePage sectionsData={sectionsData} aboutData={aboutData} />} />
                    <Route path="/terms-of-service" exact element={<HTMLFromDataPage data={termsOfServiceData} />} />
                    <Route path="/privacy-policy" exact element={<HTMLFromDataPage data={privacyPolicyData} />} />
                    <Route path="/press-releases" exact element={<PressReleasesPage data={pressReleasesData} />} />
                    <Route path="/disclaimer" exact element={<HTMLFromDataPage data={disclaimerData} />} />
                    {
                        pressReleases.map((pressReleaseData, key) => {
                            const path = "/press-releases/" + key

                            return (
                                <Route path={path} key={key} exact element={<PressReleasePage data={pressReleaseData} />} />
                            )
                        })
                    }
                </Routes>
            </Router>
            <Footer data={footerData} />
        </Fragment>
    )
}

export default App